import React from 'react';
import './App.css';
import Page from './components/page.js'
import Pages from './components/pages.json';
import {
  Switch,
  Route
} from "react-router-dom";


export default function App() {
  return (
    <div key="portfolio_app" className="App">
      <Switch>
        {generate_route_arr()}
        <Route key={"landing_route"} path={'/'}>
          <Page key={"landing_page"} page={"landing"}/>
        </Route>
      </Switch>
    </div>
  );
}

function generate_route_arr() {
  const route_arr = [];

  // All Main Routes (except landing page)
  for(let index=0; index < Pages.length; index++){
    route_arr.push(
      <Route key={Pages[index] + "_route"} path={'/' + Pages[index]}>
        <Page key={Pages[index] + "_page"} page={Pages[index]}/>
      </Route>
    )
  }

  
  return route_arr;
}
