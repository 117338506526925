import React from 'react';
import './top_nav.css';
import LogoSection from './1_logo_section/logo_section.js';
import PageLinksSection from './2_page_links_section/page_links_section.js';

export default class TopNav extends React.Component {

  render() {
    return (
      <header>
        <nav id="top_nav">
          <LogoSection />
          <PageLinksSection />
          <section id={"mobile_menu_section"}>
            <button type="button" className="mobile_menu_btn" onClick={menu_dropdown_up}>
              {'<'}
            </button>
          </section>
        </nav>
      </header>
    );
  }
}

function menu_dropdown_up() {
  var navigation_menu = document.getElementById("page_links");
  if (navigation_menu.className === "") {
    navigation_menu.className += "responsive";
  } 
  else {
    navigation_menu.className = "";
  }
}