import React from 'react';
import './page_link_section.css';
import Pages from '../../../pages.json';
import {NavLink} from "react-router-dom";

export default class PageLinksSection extends React.Component {
  render() {
    const pages_arr = Pages.map((page) =>
      < NavLink 
        key={page + '_link'}
        value={page}
        to={'/' + page} >
          {page.charAt(0).toUpperCase() + page.slice(1)}
      </NavLink>
    );
    return (
      <section id="page_links">
        {pages_arr}
        < a 
          className={"icon_link"}
          key={'facebook_link'}
          value={"facebook_page"}
          href={'https://facebook.com'} >
            <img
              id= "Facebook_logo"
              alt= "F"
              src= "/images/contact_links/facebook.svg" />
        </a>
        < a 
          className={"icon_link"}
          key={'instagram_link'}
          value={"instagram_page"}
          href={'https://www.instagram.com'} >
            <img
              id= "Instagram_logo"
              alt= "I"
              src= "/images/contact_links/instagram.svg" />
        </a>
      </section>
    );
  }
}