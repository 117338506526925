import React from 'react';
import './styles/services_page.css';
import PageTitle from '../global/body_components/page_title/page_title';
import PageText from '../global/body_components/page_text/page_text';

export default class ServicesPage extends React.Component {
  render() {
    return (
      <section id={"bookings_page_content"}>
        <PageTitle content="Services" />
        <PageText content="Content to be added later" />
      </section>
    );
  }
}


