import React from 'react';
import './styles/bookings_page.css';
import PageTitle from '../global/body_components/page_title/page_title';
import PageText from '../global/body_components/page_text/page_text';

export default class BookingsPage extends React.Component {
  static getDerivedStateFromProps(props) {
    return {page: props.page };
  }

  render() {
    return (
      <section id={"bookings_page_content"}>
        <PageTitle content="Bookings" />
        <PageText content="Content to be added later" />
      </section>
    );
  }
}