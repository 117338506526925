import React from 'react';
import './footer.css';

export default class Footer extends React.Component {

  render() {
    return (
      <footer>
        <p id="copyright_footer">Handidowns</p>
      </footer>
    );
  }
}
