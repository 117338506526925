import React from 'react';
import './styles/home_page.css';
import PageTitle from '../global/body_components/page_title/page_title';
import PageText from '../global/body_components/page_text/page_text';

export default class HomePage extends React.Component {
  render() {
    return (
      <section id={"home_page_content"}>
        <PageTitle content="Handyman Extrodinare" />
        <PageText content="Let's resist entropy and restore together" />
      </section>
    );
  }
}