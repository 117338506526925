import React from 'react';
import './logo_section.css';
import {NavLink} from "react-router-dom";

export default class LogoSection extends React.Component {
  render() {
    return (
      <section id={"logo_section"}>
        < NavLink 
          id={"home_logo"}
          key={'home_link'}
          value={"home_page"}
          to={'/'} >
            <img
              id= "Handidowns_logo"
              alt= "Handidowns Logo"
              src= "/images/logo.svg" />
        </NavLink>
      </section>
      
    );
  }
}