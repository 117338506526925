import React from 'react';
import TopNav from './global/top_nav/top_nav'

// Page main components render depending on page set by the URL.
// To add new pages you must create a new page js file and add it
// to this program's imports and render_url_matched_page function
import HomePage from './pages/home_page.js';
import ServicesPage from './pages/services_page.js';
import ReviewsPage from './pages/reviews_page.js';
import BookingsPage from './pages/bookings_page.js';

import Footer from './global/footer/footer'

export default class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {page: ""};
  }

  static getDerivedStateFromProps(props) {
    return {
      page: props.page,
      index: props.index
    };
  }

  render() {
    return (
      <section id={this.state.page}>
        <TopNav />
        {render_url_matched_page(this.state.page, this.state.index)}
        <Footer />
      </section>
    );
  }
}

function render_url_matched_page(page, index) {
  // console.log(index);
  switch (page) {
    case "services":
      return <ServicesPage />;
    case "reviews":
      return <ReviewsPage />;
    case "bookings":
      return <BookingsPage />;
    default:
      return <HomePage />;
  }
}